<template lang="pug">
  div.app
    div.app__container
      div.app__inner
        div.app__img
          img(
            :src="App"
            alt="telephone"
          )
        div.app__info
          h3.title.app__title
            | Взаємодія з застосунком
            |
            span STARLINK
          ul.app__list
            li(
              v-for="(item, index) in appList"
              :key="index"
              class="app__item"
            ) {{ item.title }}
          div.app__links
            a(href="https://apps.apple.com/us/app/starlink/id1537177988").link Завантажити на iOS
            a(href="https://play.google.com/store/apps/details?id=com.starlink.mobile").link Завантажити на Android
</template>

<script>
// images
import App from "@/assets/images/app-img.png"

export default {
  name: 'AppBlock',
  data () {
    return {
      App,
      appList: [
        { title: 'Перевірка і поповнення балансу.' },
        { title: 'Діагностика і налаштування мережі.' },
        { title: 'Випробування швидкості і якості передачі сигналу.' },
        { title: 'Керування наявними пристроями.' },
        { title: 'Пряма техпідтримка Starlink.' }
      ]
    }
  }
}
</script>
